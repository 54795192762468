import { useState, useEffect } from "react";
import constants from "constants/constants";

/** @deprecated use useDesktopMediaQuery from src/features/Common/hooks/useDesktopMediaQuery.js */
const useDesktopMediaQuery = () => {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width >= constants.mobile_width;
};

export default useDesktopMediaQuery;
