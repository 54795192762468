import { useState, useCallback, useRef } from "react";

// loadMore function is slightly better (but put an option since lot other already use useEffect way)

const useInfiniteSearch = (props) => {
  const { loadMore } = props || {};
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const observer = useRef(null);

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (loadMore) {
            loadMore(pageNumber + 1);
          } else {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, observer, hasMore]
  );

  return {
    data,
    setData,
    loading,
    error,
    hasMore,
    pageNumber,
    setPageNumber,
    lastElementRef,
    setLoading,
    setError,
    setHasMore,
  };
};

export default useInfiniteSearch;
