import { useDispatch, useSelector } from "react-redux";
import { EXLY_SCHEDULELIST__UPDATE_OFFERING } from "ui/pages/schedule/redux/actions";
import { OFFERING_STATUSES, OFFERING_STATUSES_LIST } from "constants/schedule";
import { isInteger, is_empty } from "utils/validations";
import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { logError } from "utils/error";
import { linked_keys } from "features/Listings/Listings.constants";

/**
 * @deprecated use
 * |OLD   | NEW  |
 * |---|---|
 * |fetchOfferingDetails   |useFetchListingDetails in src/features/Listings/utils/useFetchListingDetails.js   |
 * |getListingFromRedux   |useGetReduxListingDetails in src/features/Listings/utils/useGetReduxListingDetails.js   |
 *
 * TODO: break down this hook into smaller hooks and move to feature folder
 */
export const useOfferings = () => {
  const dispatch = useDispatch();
  const offerings = useSelector((state) => state.schedule);
  // const noData = offerings.noData;

  const getListingFromRedux = (listing_uuid) => {
    let listing;

    for (let key of ["live", "draft", "past", "soldout"]) {
      listing = offerings[key].data.find((item) => item.uuid === listing_uuid);
      if (!is_empty(listing)) break;
    }
    return listing;
  };

  const fetchOfferings = (offering_status) => {
    if (is_empty(offering_status)) return;
    let action = OFFERING_STATUSES[offering_status].action;

    dispatch({
      type: action,
      payload: { fetchAll: true },
    });
  };

  const getListingsBasedOnStatus = (status) => {
    return offerings[status] || [];
  };

  const updateReduxListing = (updatedOffering) => {
    if (is_empty(updatedOffering.uuid) || !isInteger(updatedOffering.status))
      return;

    dispatch({
      type: EXLY_SCHEDULELIST__UPDATE_OFFERING,
      payload: {
        offeringStatus: OFFERING_STATUSES_LIST[updatedOffering.status],
        updatedOffering,
      },
    });
  };

  const fetchOfferingDetails = async ({
    offeringUuid,
    forceFetch = false,
    onStart,
    onEnd,
    onError,
  }) => {
    try {
      if (is_empty(offeringUuid)) throw new Error("offeringUuid missing!");
      if (onStart) onStart();
      let selectedOffering = getListingFromRedux(offeringUuid);
      if (is_empty(selectedOffering) || forceFetch === true) {
        const response = await dataProvider.custom_request(
          `${api.get_listing_details}/${offeringUuid}`
        );
        selectedOffering = response?.data?.event;
      }
      return selectedOffering;
    } catch (error) {
      logError({
        error,
        occuredAt: "src/hooks/useOfferings.js",
        when: "on fetchOfferingDetails",
      });
      if (onError) onError(error);
    } finally {
      if (onEnd) onEnd();
    }
  };

  const updateOffering = async ({
    offeringUuid,
    updatedValues,
    onStart,
    onEnd,
    onError,
    onSuccess,
    gzip_encoding = false, //boolean: whether to enable gzip compression for your POST call; by default false
  }) => {
    try {
      if (is_empty(offeringUuid) || is_empty(updatedValues))
        throw new Error("offeringUuid || updatedValues missing!");
      if (onStart) onStart();
      const response = await dataProvider.custom_request(
        api.update_listing,
        apiMethods.POST,
        { uuid: offeringUuid, updated_values: updatedValues },
        gzip_encoding
      );
      if (onSuccess) {
        onSuccess(response);
        const tempListingData = await fetchOfferingDetails({
          offeringUuid,
          //@dev it check if any keys from 'linked_keys' are present in 'updatedValues' to determine if a data fetch is needed.
          forceFetch: linked_keys.some((key) =>
            Object.keys(updatedValues).includes(key)
          ),
        });
        updateReduxListing(Object.assign(tempListingData, updatedValues));
      }
    } catch (error) {
      logError({
        error,
        occuredAt: "src/hooks/useOfferings.js",
        when: "on updateOffering",
      });
      if (onError) onError(error);
    } finally {
      if (onEnd) onEnd();
    }
  };

  const fetchOfferingsList = (payload = {}) => {
    return dataProvider.custom_request(
      api.fetch_offerings,
      apiMethods.GET,
      payload
    );
  };

  return {
    getListingFromRedux,
    fetchOfferings,
    getListingsBasedOnStatus,
    updateReduxListing,
    fetchOfferingDetails,
    updateOffering,
    fetchOfferingsList,
  };
};
